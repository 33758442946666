@import "../variables.scss";

.title{
    position: relative;
    display: flex;
    justify-content: center;
    position: relative;
    width: auto;
    padding: 5rem 0;
    text-transform: uppercase;
    h2{
        letter-spacing: .5rem;
    }
}

.bg-title{
    display: flex;
    justify-content: center;
    position: absolute;
    // color:$color-accent;
    width: 100%;
    top: 0;
    left: -10rem;
    font-size: 7rem;
    opacity: .1;
    font-weight: 800;
    @media screen and (max-width:576px) {
        font-size: 3rem;
        top: 4rem;
        left: 0;
    }
}

 