@import "../variables.scss";

.About{
  position: relative;
  z-index: 0;
  height: auto;
  padding: 5rem 0;
  color: $color-primairy;
  @media screen and (max-width:1024px) {
    padding: 2.5rem 0;
  }
}

.about{
  &-item{
    flex: 0 0 auto;
    width: 50%;
    @media screen and (max-width:1024px) {
      flex: 0 0 auto;
      width: 100%;
    }
    padding: 0 1rem;
    h2{
      font-size: 1.75rem;
      text-transform: uppercase;
      letter-spacing: .5rem;
      @media screen and (max-width:1024px) {
        font-size: 1.5rem;
      }
      @media screen and (max-width:576px) {
        font-size: 1.25rem;
        letter-spacing: .25rem;
      }
    }
    p{
      padding: 2rem 0;
    }
    img{
      width: 100%;
      height: 35rem;
      object-fit: cover;
      @media screen and (max-width:1024px) {
        height: auto;
      }
    }
  }
  &-table{
    padding: 2.5rem 0;
  }
}

.table-of-content {
  width: 100%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  a {
    font-style: italic;
    font-weight: 200;
    color: $color-primairy;
    text-decoration: none;
    transition: all 0.5s ease-out;
    &:hover {
      font-weight: 300;
      color: #caa35c;
      font-style: italic;
      transition: all 0.25s ease-in;
    }
  }
  .content-item {
    list-style-type: none;
    padding: 0.5rem 0;
  }
}
