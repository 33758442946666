@import "../variables.scss";

.introduction {
  background-color: $color-primairy;
  .intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 25rem;
    padding: 2.5rem 0;
    @media screen and (max-width: 1024px) {
      height: 15rem;
    }
    h2 {
      text-transform: uppercase;
      letter-spacing: 0.25rem;
    }
    p {
      font-size: 0.9rem;
      width: 40%;
      font-weight: $font-weight-text;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }
}

.objects {
  width: 100%;
  height: 100%;
}

.items {
  position: relative;
  width: 100%;
  margin-top: -8rem;
  z-index: 0;
  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }

  .item-project {
    position: relative;
    width: 100%;
    height: 50rem;
    margin-top: -15rem;
    overflow: hidden;
    @media screen and (max-width: 1024px) {
      margin-top: -2rem;
      height: auto;
      padding-bottom: 5rem;
    }
    &-link {
      height: 50rem;
      width: 100%;
    }
    &-location {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      height: auto;
      padding: 1.5rem 3.5rem;
      color: $color-primairy;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      opacity: 0.75;
      @media screen and (max-width: 1024px) {
        padding: 1rem 1rem;
      }
    }

    &-image {
      position: relative;
      img {
        width: 100%;
        height: 35rem;
        object-fit: cover;
        padding: 0 2rem;
        @media screen and (max-width: 1024px) {
          height: 40rem;
          padding: 0;
        }
        @media screen and (max-width: 767.5px) {
          width: 100%;
          height: 22.5rem;
        }
      }
    }
  }

  .item.right {
    display: flex;
    flex-direction: column;
    align-items: flex-end !important;
  }
  .item-project {
    width: 50%;
    height: 100%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: calc(100% - 2rem);

  h3 {
    width: calc(100% - 2rem);
    white-space: nowrap;
    text-overflow: ellipsis !important;
    overflow: hidden;
    text-align: end;
    color: $color-primairy;
    text-transform: uppercase;
    margin-top: 2rem;
  }
  ul {
    text-align: end;
  }
  a {
    color: $color-primairy;
    text-decoration: none;
    font-weight: $font-weight-text;
    &:hover {
      color: $color-accent;
    }
  }
}

.z-index-3 {
  position: relative;
  z-index: 3;
}

.z-index-2 {
  position: relative;
  z-index: 2;
}

.z-index-1 {
  position: relative;
  z-index: 1;
}
