@import "../variables.scss";

.overview{
    &-link{
        &:hover{
            .overview-item.overlay,img{
                transform: scale(1.1);
                transition: all .25s ease-in;
            }
        }
    }
    &-item{
        position: relative;
        margin-top: 1.5rem;
        overflow: hidden;
        .overlay{
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom,rgba(0, 0, 0, 0), rgba(0, 0, 0, .75));
            z-index: 1;
            h2{
                display: flex;
                align-items: flex-end;
                height: 100%;
                color: $color-primairy;
                padding: 0 0 1rem 1rem;
                font-size: 2rem;
            }
        }
        img{
            position: relative;
            width: 100%;
            height: 25rem;
            object-fit: cover;
            transition: all .25s ease-out;

        }
    }
}

