@import "../variables.scss";

.values{
    background-color: $color-primairy;
    @media screen and (max-width: 1024px) {
        background-color: $color-secundairy;
    }
}

.value-box{
    display: flex;
    justify-content: center;
}

.value-item{
    display: flex;
    position: relative;
    padding: 1.75rem;
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 1rem;
    }

    h3{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        letter-spacing: .5rem;
        font-family: $title-font;
        text-transform: uppercase;
        font-weight: 800;
        font-size: $h3;
        width: 25%;
        padding: 1rem;
        z-index: 10;
        .closed-indicator{
            position: absolute;
            font-size:3rem;
            font-weight: bold;
            color: $color-accent;
            top: -.5rem;
            right:-.5rem;
            opacity: .8;
            transform: rotate(-15deg);
        }

        @media screen and (max-width: 1024px) {
            width: 100%;
            color: $color-primairy;


        }
    }
    .text{
        transform: translateY(-5rem);
        opacity: 0;
        transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    }


    p{
        position: relative;
        width: 75%;
        padding: 2rem;
        font-weight: $font-weight-text;
        font-size: $txt-size;
        color: $color-secundairy;
        z-index: 9;
        @media screen and (max-width:1024px) {
            width: 100%;
            text-align: center;
            color: $color-primairy;
        }
        &::before{
            content: '"';
            position: absolute;
            @media screen and (max-width:1024px) {
                font-size:7.5rem;
                font-weight: bold;
                color: $color-accent;
                top: -2.5rem;
                right: 10%;
                opacity: .35;
                z-index: -1;
                transform: rotate(-15deg);
            }
        }


    }
}


.text-slide-in{
    transform: translateY(0) !important;
    opacity: 1 !important;
    transition: all .25s ease-out;  
}


.click-me{
    position: absolute;
    width: 7rem;
    left: -7rem;
    top: -2.5rem;
    @media screen  and (max-width:576px) {
        width: 6rem;
        left: -5rem;
    }
}