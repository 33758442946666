@import "../variables.scss";
.wayofwork {
  padding: 5rem 0;
  &-page{
    position: relative;
  }
  &-list {
    display: flex;
    width: 100%;
    padding-bottom: 0 !important;
  }
  &-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 2rem 0;
    color: $color-primairy;
    @media screen and (max-width: 576px) {
        align-items: flex-start;
        flex-direction: column;
    }
    .number {
      text-align: start;
      font-size: 5rem;
      font-weight: 800;
      color: $color-accent;
      padding: 2rem 5rem;
      transform: rotate(-5deg);
      z-index: 1;
      @media screen and (max-width: 576px) {
        position: absolute;
        display: flex;
        justify-content: center;
        font-size: 7.5rem;
        padding: 1rem 2rem;
        width: 100%;
        opacity: .5;
      }
      &-symbol {
        position: relative;
        &:before {
          content: "#";
          position: absolute;
          font-size: 3rem;
          top: 0;
          left: 2.5rem;
          // color: white;
          z-index: -1;
          opacity: 0.5;
          @media screen and (max-width: 576px) {
            font-size: 4rem;
            left: 4.5rem;
          }
        }
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    li {
      font-size: 0.8rem;
      padding: 0.25rem 0;
      span {
        color: $color-accent;
      }
    }
  }
}

.pagination-navigation{
  display: flex;
  justify-content: flex-end;
}
.next-btn{
  align-items: center;
  font-size: 2rem;
  font-weight: 800;
  background-color: transparent;
  border: none;
  color: $color-primairy;
  padding-left: 1rem;
  border-left: .5rem solid $color-accent;
  @media screen and (max-width: 992px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 576px) {
    font-size: 1.25rem;
  }

}

.prev-btn{
  background-color: transparent;
  border: none;
  color: $color-primairy;
  font-size: 1rem;
  font-weight: 800;
  padding-right: 1rem;
  @media screen and (max-width: 992px) {
    font-size: .9rem;
  }
  @media screen and (max-width: 576px) {
    font-size: .8rem;
  }

}

.disable-btn:disabled{
  opacity: .5;
}