@import "./variables.scss";

.logo{
  // animation: fade-in .75s ease-in;
    img{
        width: 5rem;
        margin-left: -1rem;
        @media screen and (max-width: 992px) {
            width: 3.5rem;
        }
    }
}
.navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0 1rem 0;
}



.nav-overlay {
  position: fixed;
  background: linear-gradient(to bottom,rgba(0, 0, 0, .75), rgba(0, 0, 0, 1));
  z-index: 1;
  // top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow: hidden;
  padding-left: 2rem;
  transition: all 0.5s ease-in;
  @media screen and (max-width:576px) {
    padding-left: unset;
}

}


.nav-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  font-size: 2rem;
  color: white;
  list-style-type: none;
  @media screen and (max-width:576px) {
    font-size: 1.75rem;
  }
}

.nav-item {
  margin: 1rem;
  a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: white;
    font-family: soleil, sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    z-index: 0;
    &:hover {
      color: #caa35c;
      transition: all 0.25s ease-in;
      &::after {
        width: 100%;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: .25rem;
      bottom: 0;
      left: 0;
      background-color: #caa35c;
      transform-origin: bottom right;
      transition: width 0.25s ease-in;
      z-index: -1;
    }
    &.active::after {
      width: 100%;
    }
  }
}
.menu-toggle {
  position: fixed;
  top: 4.5rem;
  cursor: pointer;
}

.box {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 3rem;
  z-index: 0;
  &:hover .barTop {
    width: 25px;
    transition: all 0.25s ease-out;
  }
  &:hover .barBot {
    width: 25px;
    transition: all 0.25s ease-out;
  }
}


.barTop,
.barMid,
.barBot {
  margin-left: auto;
  position: absolute;
  display: block;
  width: 25px;
  height: 5px;
  border-right: 2px solid $color-secundairy;
  border-bottom: 2px solid $color-secundairy;
  right: 0;
  background-color: $color-primairy;
  transition: all 0.5s ease;
  mix-blend-mode: difference;
}

.barTop {
  top: 1rem;
  width: 15px;
  transition: all .5s ease-out;
}
.barMid {
  top: 1.5rem;
}
.barBot {
  top: 2rem;
  width: 10px;
  transition: all 0.5s ease-out;
}

//Hamburger animations

.barTop.width {
  top: 1rem;
  width: 0;
  border-right: 0 solid $color-secundairy;
  background-color: white;
  transition: all 0.25s ease-out;
}
.barMid.color {
  top: 1.5rem;
  width: 0;
  background-color: transparent ;
  opacity: 0;
}
.barBot.width {
  top: 2rem;
  width: 0;
  border-right: 0 solid $color-secundairy;
  background-color: white;
  transition: all .5s ease-out;
}


.white-menu .barTop,
.white-menu .barMid,
.white-menu .barBot {
  background-color: black;
}


// Navbar top
.navbarTop {
  display: flex;
  justify-content: flex-end;
}

.navTop {
  &-items {
    display: flex;
    height: 2.5rem;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1400.5px) {
      width: 100%;
    }
  }
  &-item {
    display: flex;
    justify-content: space-around;
    &.hover{
        &.Item, .itemIcon.white{
            svg{
                fill:#caa35c;
            }
        }
    }
  }
  &-item.white {
    margin: 1rem 0 1rem 3rem;
    color: white;
    text-decoration: none;
  }
}



.contactLinks{
    text-decoration: none;
    color: black;
    padding: .5rem .5rem .5rem 0;
    &:hover{
      opacity: 0.5;
      transition: all .25s ease-in;
    }
    .itemIcon{
      img{
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
      }
    }

}

.socialLink{
    height: auto;
    padding: .5rem 0 .5rem 1rem;
    transition: all .25s ease-out;
    &:hover{
      opacity: 0.5;
      transition: all .25s ease-in;
    }
    .Icon{
      img{
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
      }
    }

}

.socialLink.white {
  margin: 1rem 0 1rem 2rem;
  

}

.itemIcon {
  margin-right: 1rem;
  @media screen and (max-width:576px) {
    margin-right: unset;
  }
}


// Background Nav 

.bgs{
    display: none;
    position: fixed;
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-top: 1rem;
    color: white;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
}

.bgPosition{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 20rem;
    height: 20rem;
}
.bg{
    position: absolute;
    transform: rotate(45deg);
    &-1{
        width: 15rem;
        height: 15rem;
        border: 2rem solid #caa35c;
        @media screen and (max-width:992px) {
            width: 12.5rem;
            height: 12.5rem;
            border: 1.5rem solid rgb(202, 163, 92, .5);
        }
    }
    &-2{
        top: 10rem;
        left: 20rem;
        width: 12.5rem;
        height: 12.5rem;
        border: 2rem solid #caa35c;
        @media screen and (max-width:992px) {
            display: none;
        }
    }
    &-3{
        top: 20rem;
        left: 10.5rem;
        width: 10rem;
        height: 10rem;
        border: 2rem solid #caa35c;
        @media screen and (max-width:992px) {
            top: 16rem;
            left: 10.5rem;
            width: 10.5rem;
            height: 10.5rem;
            border: 1.5rem solid rgb(202, 163, 92, .5);
        }
    }


}


// Menu close bar


.nav-elements{
  position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1320px;
    z-index: 0;

}

.close-togle{
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  right: 1.1rem;
  width: 100%;
  height: 5rem;
  margin-top: 4.3rem;
  z-index: 10;
  button{
    width: 25px;
    height: 2rem;
    &:hover .close-bar{
      width: 50%;
      transition: all .25s;
    }
  }

  @media screen and (max-width: 992px) {
    right: 0;
  }
  .close-bar{
    width: 0;
    height: 4px;
    background-color: white;
    opacity: 0;
    &-visible{
      width: 25px;
      opacity: 1;
      transition-delay: .5s;
      transition: all .75s ease-in;
    }

  }
  button{
    background-color: transparent !important;
    border: none;
    
  }
}