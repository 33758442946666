@import "../variables.scss";

.component {
  margin-top: -9rem;
  @media screen and (max-width: 992px) {
    margin-top: -10rem;
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: -9rem;
  padding: 10rem 0 0 0;
  overflow-x: hidden;
  @media screen and (max-width: 992px) {
    height: auto;
  }

  @media screen and (max-width: 576px) {
    margin-top: -10rem;
  }

  &-object {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 2rem 2rem 1rem 0;
    color: $color-primairy;
    // min-width: 100vw;
    @media screen and (max-width: 992px) {
      padding: 5rem 0 4rem 0;
      justify-content: flex-start;
      min-width: unset;
    }

    p {
      position: relative;
      width: 100%;

      @media screen and (max-width: 992px) {
        text-align: start;
        font-size: 0.8rem;
        width: 100%;
      }
    }
  }

  &-return {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    transition: all 0.25s ease-in;
    cursor: pointer;
    z-index: 998;
    @media screen and (max-width: 576px) {
      margin-bottom: 0.8rem;

      font-size: 0.8rem;
      letter-spacing: 0.4rem;
    }
    &:hover {
      transition: all 0.25s ease-in;
      color: $color-accent;
    }
  }

  &-title {
    h1 {
      position: relative;
      width: 100vw;
      font-size: 2.5rem;
      text-transform: uppercase;
      letter-spacing: 0.5rem;

      @media screen and (max-width: 992px) {
        width: 100%;
        font-size: 1.5rem;
      }

      @media screen and (max-width: 576px) {
        font-size: 1.25rem;
      }
    }
  }

  &-image {
    height: 100%;
    img {
      width: 100%;
      height: 40rem;
      object-fit: cover;
      @media screen and (max-width: 576px) {
        height: 25rem;
      }
    }
  }
}
