@import '../variables.scss';


.Overview{
    background-color: $color-primairy;
}
.c-references{
    position: relative;
    padding: 5rem 0 5rem 0;
    animation: fadeIn .8s ease-in-out; /* Adjust duration and timing function as needed */

    &__arrow{
        margin-top: .5rem;
        transition: all .25s ease-out; 
    }
    &__overflowImage{
        filter: grayscale(1);
        transition: all .25s ease-out; 
        @media screen and (max-width: 992.5px) {
            filter: grayscale(0);
        }
    }
    &__link{
        &:hover .c-references__arrow{
            transition: all .25s ease;
            transform: translateX(-.75rem);
        }
        &:hover .c-references__overflowImage{
            transition: all .25s ease;
            filter: grayscale(0);
        }
        &:hover .c-references__overlay{
            transition: all .25s ease-in;
            background-color: rgb(0, 0, 0, .15);
            @media screen and (max-width: 992.5px) {
                background-color: rgb(0, 0, 0, .5);
            }
            

        }

    }
    &__item{
        transition: transform 0.3s ease-in-out;
    }
    &__image{
        position: relative;
        img{
            width: 100%;
            height: auto;
            object-fit: cover; 
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
           
        }

    }
    &__overlay{
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: linear-gradient(to bottom,rgba(0, 0, 0, 0), rgba(0, 0, 0, .5));
        transition: all .25s ease-out;
    }
    &__label{
        position: absolute;
        top: -.5rem;
        left: -.5rem;
        background-color: $color-secundairy;
        color: $color-primairy;
        font-weight: 700;
        padding: .75rem 1rem;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    }
    &__subject{
        font-size: .85rem;
        letter-spacing: .25rem;
        text-transform: uppercase;
    }
    &__arrow{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-right: 1rem;
    }
}

.animation{
    top: 1rem !important;
    animation: fadeInFilter .8s ease-in-out; /* Adjust duration and timing function as needed */
}

  
// ANIMATION Masonry grid 


@keyframes fadeInFilter {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    //   transition: all .45s ease-in;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    //   transition: all .45s ease-in;

    }
  }
@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    //   transition: all .45s ease-in;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    //   transition: all .45s ease-in;

    }
  }
  