// Colors
$color-primairy: white;
$color-secundairy: black;
$color-accent: #caa35c;

// Font & sizes 

$title-font: soleil , sans-serif;
// $title-font: bely-display, sans-serif;
$txt-font: soleil, sans-serif;
$h1: 1.75rem;
$h2: 1.5rem;
$h3: 1.3rem;
$small-title:1rem;
$txt-size: 1rem;
$txt-size-small: .85rem;
$font-weight-text: 300;


