@import '../variables.scss';


.Footer{
    color: $color-primairy;
    height: auto;
    padding: 5rem 0 .5rem 0;
    background-image: url(../../images/backgrounds/footer/logo.png);
    background-repeat: no-repeat;
    background-position: center center;
    @media screen and (max-width:992px) {
        background-size: 100%;
    }

}


.first-row{
    h2{
        font-family: $title-font;
        font-size: $h2;
        text-align: start;
        text-transform: uppercase;
        @media screen and (max-width:992px) {
            font-size: 1.25rem;
        }
    }
    h3{
        font-family: $title-font;
        font-size: 1rem;
        text-align: start;
        text-transform: uppercase;
    }
    .cta-form{
        width: 75%;
        @media screen and (max-width:992px) {
            width: 100%;
            // padding: 0 1rem;
        }
        p{
            font-weight: $font-weight-text;
            font-size: .8rem;
            width: 75%;
            @media screen and (max-width:992px) {
                width: 90%;
            }
        }
    }
    form{
        display: flex;
    }
    input{
        width: 100%;
        height: 2.5rem;
        border: none;
        border-radius: unset;
        padding-left: 1rem;
        font-size: .8rem;
    }
    input::placeholder{
        font-size: .8rem;
    }
    .data-contact{
        @media screen and (max-width:992px) {
            width: 100%;
            // padding-left:1rem;
            ul{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }
        a{  
            color: $color-primairy;
            text-decoration: none;
            font-size: $txt-size;
            transition: all .25s ease-out;
            &:hover{
                opacity: .5;
                transition: all .25s ease-in;
            }
        }
    }
    .data-social{
        @media screen and (max-width:992px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 100%;            
        }
    }


}

.send{
    display: flex;
    align-items: center;
    margin-left: 1.75rem;
    &-button{
        background-color: transparent;
        border: none;
        color: $color-accent;
        transform: rotate(-45deg);
        transition: all .25s ease-out;
        &:hover{
            transform: rotate(-22.5deg);
            transition: all .25s ease-in;
        }
        svg{
            width: 1.75rem;
            height: 1.75rem;
        }
    }
}

.last-row{
    .conditions{
        ul{
            margin-bottom: 0 !important;
        }
        &-item{
            padding: .25rem 0 0 0;
        }

        a{
            color: $color-primairy;
            text-decoration: none;
            font-weight: $font-weight-text;
            font-size: .8rem;
            &:hover{
                color: $color-accent;
            }
        }
    }
    .made-by{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        height: 100%;
        font-size: .8rem;
            color: $color-accent;
            text-decoration: none;
            font-weight: 300;
    }
}


.data-item{
    padding: 1.25rem 0;
    &-text{
        margin-top: .1rem;
        @media screen and (max-width:992px) {
            display: none;
        }
    }
    svg{
        fill: $color-primairy;
        margin-right: 1rem;
    }
    img{
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
    }
}