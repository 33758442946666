@import "../../scss//variables.scss";


.custom-container {
  margin-left: 0;
  margin-right: auto;
  max-width: 660px;
  @media screen and (max-width: 1024px) {
    max-width: 100%;
    margin-left: auto;
  }
}

.service {
  width: 100%;
  height: 100%;

  &-image-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    color: $color-primairy;
    background-color: rgb(0, 0, 0, 0.25);
    h3 {
      position: absolute;
      // width: 20rem;
      left: 2.5rem;
      top: 5rem;
      font-family: $title-font;
      font-size: 3.5rem;
      font-weight: 800;
      @media screen and (max-width: 1024px) {
        position: unset;
        top: 2rem;
        left: 1rem;
        font-size: 2rem !important;
      }
    }
    p {
      padding: 1rem 3.5rem;
      font-size: $txt-size;
      font-weight: $font-weight-text;
    }
  }
  &-image {
    object-fit: cover;
    transition: all 0.5s ease; /* Add a transition for the 'slide' effect */
    @media screen and (max-width: 1024) {
      width: 100%;
    }
  }

  .img-left {
    position: relative;
    width: 50%;
    height: 45rem;
    @media screen and (min-width: 2000px) {
      height: 75rem;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 45rem;
    }
    @media screen and (max-width: 576px) {
      height: 22.5rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content-right {
    width: auto;
    position: relative;
    height: 50rem;
    padding: 0 1rem 2rem 1rem;
    color: $color-primairy;
    z-index: 0;
    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 100%;
    }
  }


  .service-items {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 2rem;
    @media screen and (max-width:992px) {
        justify-content: center;
    }
  }


  .service-link {
    text-decoration: none;
    color: $color-primairy;
    transition: color 0.25s ease-out;
    &:hover {
      color: $color-accent;
      transition: color 0.25s ease-in;
    }
  }


  .service-list{
    display: block;
    @media screen and (max-width:1024px) {
      display: flex;
      flex-wrap: wrap;      
    }

  }

  .service-list-item {
    position: relative;
    font-family: $title-font;
    font-size: $h2;
    font-weight: 800;
    padding: 1rem;
    margin-left: 1.5rem;
    @media screen and (max-width:576px) {
        font-size: 1.25rem;
        padding: .5rem;
        margin-left: unset;
    }
    h3{
      @media screen and (max-width:1024px) {
        border: 2px solid $color-accent;
        padding: 1rem;
        letter-spacing: .25rem;
      }

    }
    &-subject {
      font-size: 1rem;
      margin-left: 0.5rem;
      @media screen and (max-width:1024px) {
        display: none;
      }
      
    }
  }



  .service-list-item::before {
    font-family: $txt-font;
    counter-increment: li;
    content: counter(li, decimal-leading-zero);
    position: absolute;
    top: 1rem;
    left: -1.25rem;
    color: $color-primairy;
    font-size: 0.75rem;
    @media screen and (max-width: 576px) {
      visibility: hidden;
    }
  }
  &-cta {
    width: 80%;
    margin-left: 2.5rem;
    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-left: unset;
      padding: 1rem 0;
    }
    p {
      padding: 1rem;
      font-size: $txt-size-small;
      font-weight: $font-weight-text;
    }
  }
}


.flex-mobile {
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
}
