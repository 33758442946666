@import "../variables.scss";

.Contact{
    height: auto;
    padding: 5rem 0;
    color: $color-primairy;
    @media screen and (max-width:992px) {
        height: 100%;
        
    }
}

.contact{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto;
    @media screen and (max-width:992px) {
        height: 100%;
        
    }
}