@import "../variables.scss";

.text{
    color:$color-primairy;
    padding: 2.5rem 0;
    &-content{
        position: relative;
        width: 100%;
        z-index: 0;
        p{  
            position: relative;
            max-width: 920px;
            z-index: 2;
        }
    }

}