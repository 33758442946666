@import "../variables.scss";

.before-after{
    padding:0 0 5rem 0;
    background-color: $color-primairy;
    color: $color-secundairy;
}

.slider-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    // margin: 0 auto;
    z-index: 0;
    @media screen and (max-width:992px) {
      flex-direction: column;
    }
  }
  
  .slider-element{
    position: relative;
    max-width: 65%;
    @media screen and (max-width:992px) {
      max-width: 100%;
    }

  }
  .slider {
    display: flex;
    width: 100%;
    min-width: 50%;
    transition: transform 0.5s ease-in-out;
    overflow: hidden;
    @media screen and (max-width:992px) {
      width: 100%;
      min-width: 100%;
    }


  }
  
  .slide {
    flex: 0 0 100%;
    transition: transform 0.5s ease-in-out;
    img {
      width: 100%;
      height: 45rem;
      object-fit: cover;
      @media screen and (max-width:992px) {
        height: 30rem;
      }
    }

  }
  
  .active {
    opacity: 1;
  }
  

  
  .slider-button {
    position: absolute;
    bottom: 2rem;
    width: 4rem;
    height: 4rem;
    padding: 10px;
    background-color: $color-accent;
    border: none;
    cursor: pointer;
    z-index: 1;
    &:hover{
      background-color: $color-primairy;
      transition: all .25s ease-in;
      svg{
        fill: $color-secundairy;
        transition: all .25s ease-out;
      }
    }
    @media screen and (max-width:992px) {
      bottom: 0;
    }
    svg{
      width: 1rem;
      fill: $color-primairy;
    }
    &-prev{
        right: 0;
        @media screen and (max-width:992px) {
          right: 4rem;
        }
    }
    &-next{
        right: -4rem;
        @media screen and (max-width:992px) {
          right: 0;
        }
    }
  }

  .slider-counter{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 1rem;
    font-size: 3rem;
    font-weight: 800;
    @media screen and (max-width:992px) {
      font-size: 2rem;
    }
  }

  