@import "../variables.scss";

.Button {
  width: 100%;
  color: $color-primairy;
  text-decoration: none;
  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
  &-link {
    display: flex;
    width: auto;
    text-decoration: none;
    &--cookies{
      display: block;
      padding: .25rem 0;
    }
    @media screen and (max-width:1024px) {
      width: 100%;
    }
  }
  &-txt {
    width: auto;
    height: auto;
    padding: 1rem 3rem;
    background: $color-accent;
    text-align: center;
    --c: #caa35c; /* the color*/

    box-shadow: 0 0 0 0.1em inset var(--c);
    --_g: linear-gradient(var(--c) 0 0) no-repeat;
    background: var(--_g) calc(var(--_p, 0%) - 100%) 0%,
      var(--_g) calc(200% - var(--_p, 0%)) 0%,
      var(--_g) calc(var(--_p, 0%) - 100%) 100%,
      var(--_g) calc(200% - var(--_p, 0%)) 100%;
    background-size: 50.5% calc(var(--_p, 0%) / 2 + 0.5%);
    outline-offset: 0.1em;
    transition: background-size 0.4s, background-position 0s 0.4s, color 0.4s;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}

.Button-txt:hover {
  color: white;
  --_p: 100%;
  transition: background-position 0.4s, background-size 0s, color 0.4s;
}
.Button-txt:active {
  box-shadow: 0 0 9e9Q inset #0009;
  background-color: var(--c);
  color: #fff;
}

//Load more Button
.more-button {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2.5rem 0;
  .load-more {
    background-color: transparent;
    border: none;
    &-txt {
      color: $color-secundairy;
      background-color: $color-accent;
      padding: 0.5rem 1rem;
      box-shadow: 0 0 0 0.1em inset var(--c);
      --_g: linear-gradient(var(--c) 0 0) no-repeat;
      background: var(--_g) calc(var(--_p, 0%) - 100%) 0%,
        var(--_g) calc(200% - var(--_p, 0%)) 0%,
        var(--_g) calc(var(--_p, 0%) - 100%) 100%,
        var(--_g) calc(200% - var(--_p, 0%)) 100%;
      background-size: 50.5% calc(var(--_p, 0%) / 2 + 0.5%);
      outline-offset: 0.1em;
      transition: background-size 0.4s, background-position 0s 0.4s, color 0.4s;
    }
  }
}

.load-more-txt:hover {
  color: $color-primairy !important;
  --_p: 100%;
  transition: background-position 0.4s, background-size 0s, color 0.4s;
}
.load-more-txt:active {
  box-shadow: 0 0 9e9Q inset #0009;
  background-color: var(--c);
  color: #fff;
}
