@import "../variables.scss";

.subjects{
    &-object{
        padding: 2.5rem 0;
        &-image{
            position: relative;
            width: calc(100% - 2rem);
            height: 35rem;
            @media screen and (max-width: 992px) {
                width: 100%;
            }
            @media screen and (max-width:767px) {
                height: 30rem;
            }
            @media screen and (max-width:576px) {
                height: 25rem;
            }
            &--overlay{
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                padding: 2rem;
                background: rgba(0, 0, 0, .25);
                
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }            
        }

        &-content{
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 2.5rem 0;
            color: $color-primairy;
            height: 100%;
            h2{
                font-size: 2.5rem;
                text-transform: uppercase;

                @media screen and (max-width:576px) {
                    font-size: 1.5rem;
                }
            }

        }
        &-list{
            padding: 2rem 0;
            ul{
                list-style-type: square;
                padding-top: 1rem;
                padding-left: 1.5rem !important;
                padding-right: 2rem;
                li{
                    &::marker{
                        color: $color-accent;
                    }
                }

            }
        }
    }
}